import Album from "../containers/album/Album";
import Profile from "../containers/profile/Profile"

const ContactPage = () => {
    return (
        <>
            <Profile />
            <Album />
        </>
    )
}

export default ContactPage;

