import Greeting from '../containers/greeting/Greeting';
import StackProgress from '../containers/skillProgress/skillProgress';
import Skills from '../containers/skills/Skills';
import Education from '../containers/education/Education'

const Home = () => {

    return (
        <>
            <Greeting />
            <Skills />
            <StackProgress />  
            <Education />
        </>
    )
}

export default Home;
