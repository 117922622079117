import Blogs from "../containers/blogs/Blogs";

const BlogsPage = () => {
    return (
        <Blogs />
    )
}

export default BlogsPage;

