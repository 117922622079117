
import './App.scss';
import { useState, useEffect, useRef} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useLocalStorage } from "./hooks/useLocalStorage";
import SplashScreen from "./containers/splashScreen/SplashScreen";
import { splashScreen } from "./portfolio";
import { StyleProvider } from "./contexts/StyleContext";
import Header from "./components/header/Header";
import Footer from './components/footer/Footer';
import Home from './pages/home';
import ProjectsPage from './pages/projects_page';
import BlogsPage from './pages/blogs_page';
import ContactPage from './pages/contact_page';





const routeVariants = {
  initial: (custom) => ({
    x: custom, // Use custom value for the x position
  }),
  final: {
    x: "0%", 
    transition: {
      type: "spring",
      mass: 0.5,
    }
  },
  
};

function PageWrapper({ children, custom}) {
  return (
    <motion.div 
      variants={routeVariants}
      initial="initial"
      animate="final"
      custom={custom} // Pass the dynamic initialX value
    >
      {children}
    </motion.div>
  );
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  },[value]);
  return ref.current;
}

function RoutesAnimation(){
  const location = useLocation();
  const prevLocation = usePrevious(location);


  const routes = [
    { path: "/", element: <Home /> },
    { path: "/projects", element: <ProjectsPage /> },
    { path: "/blogs", element: <BlogsPage /> },
    { path: "/contact", element: <ContactPage /> },
  ];

  const paths = routes.map((route) => route.path);
  const currentIndex = paths.indexOf(location?.pathname);
  const previousIndex = paths.indexOf(prevLocation?.pathname);
  const direction = (currentIndex < previousIndex) ? "-100%" : (currentIndex > previousIndex ) ? "100%" : "0";


  return (
   
    <Routes location={location} key={location.pathname} >
      {routes.map((route, index) => {
        return (
        <Route
          key={index}
          path={route.path}
          element={<PageWrapper custom={direction}>{route.element}</PageWrapper>}
        />
        )
      }
      )}
    </Routes>

  );
}

function App() {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
  const [isShowingSplashAnimation, setIsShowingSplashAnimation] = useState(true);
  

  useEffect(() => {
    if (splashScreen.enabled) {
      const splashTimer = setTimeout(
        () => setIsShowingSplashAnimation(false),
        splashScreen.duration
      );
      return () => {
        clearTimeout(splashTimer);
      };
    }
  }, []);

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <div className={isDark ? "dark-mode" : null}>
      <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
        {isShowingSplashAnimation && splashScreen.enabled ? (
          <SplashScreen />
        ) : (
          <>
          
          <BrowserRouter>
            <Header />
            <AnimatePresence>
              <RoutesAnimation />
            </AnimatePresence>
            <Footer />
          </BrowserRouter>
       
          </>
        )}
      </StyleProvider>
    </div>
  );
}

export default App;
