import React from "react";
import "./Progress.scss";
import {techStack} from "../../portfolio";
import {Fade } from "react-awesome-reveal";
import { motion } from "framer-motion";

export default function StackProgress() {
  if (techStack.viewSkillBars) {
    return (
      <Fade >
        <div className="skills-container">
          <div className="skills-bar">
            <h1 className="skills-heading">Proficiency</h1>
            {techStack.experience.map((exp, i) => {
              const progressStyle = {
                width: exp.progressPercentage,
              };
              return (
                <div key={i} className="skill">
                  <p>{exp.Stack}</p>
                  <div className="meter">
                    <motion.div
                      className="progress-span"                     
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: false }}
                      variants={{
                        visible: { opacity: 1, width: progressStyle.width },
                        hidden: { opacity: 0.6, width: 0}
                      }}
                      transition={{
                        duration: 1.5,
                        ease: "easeInOut",
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          
        </div>
      </Fade>
    );
  }
  return null;
}
