import React, { useContext, useState, useRef, useEffect } from "react";
import "./Album.scss";
import { Fade } from "react-awesome-reveal";
import StyleContext from "../../contexts/StyleContext";


export default function Album() {
  const { isDark } = useContext(StyleContext);
  const albumRef = useRef()
 

 
  return (
    <div
  
      className={isDark ? "dark-mode-album" : "album"}
      id="album"
      ref={albumRef}
    >
     <div class="card-swiper">
  <div class="card-groups">
    <div class="card-group" data-index="0" data-status="active">
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
    </div>
    <div class="card-group" data-index="1" data-status="unknown">
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
    </div>
    <div class="card-group" data-index="2" data-status="unknown">
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
      <div class="little-card card">

      </div>
      <div class="big-card card">

      </div>
    </div>
  </div>
  <div class="card-swiper-buttons">
    <button id="hate-button" onclick="handleHateClick()">
      <i class="fa fa-times"></i>
    </button>
    <button id="love-button" onclick="handleLoveClick()">
      <i class="fa fa-heart"></i>
    </button>
  </div>
</div>

    </div>
  );
}
