import Projects from "../containers/projects/Projects";

const ProjectsPage = () => {
    return (
        <Projects />
    )
}

export default ProjectsPage;

