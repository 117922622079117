import React from "react";
import "./BusinessCard.scss";
import { Zoom } from "react-awesome-reveal";

export default function BusinessCard(){
    // image dimension: 1485 x 871 ratio: 1.75
    return (
        <Zoom damping={0.6}> 
        <div className="flip-card">
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https%3A%2F%2Fstevenlow.uk"></img>
                </div>
                <div className="flip-card-back">
                    <h1>Steven Low</h1>
                    <p>Computer Sciences Engineer</p>
                    <div className="contact-info">
                        <i className="fas fa-phone"></i>
                        <p>016-209 7389</p>
                    </div>
                    <div className="contact-info">
                        <i className="fas fa-envelope"></i>
                        <p>2002lowsy@gmail.com</p>
                    </div>
                    <div className="contact-info">
                        <i className="fas fa-globe"></i>
                        <p>https://stevenlow.uk</p>
                    </div>

                </div>
            </div>
        </div>
        </Zoom>
    )
}

